import request from '@/utils/request'


// 查询水晶账户记录列表
export function listAccount(query) {
  return request({
    url: '/biz/userCrystalAccountBill/list',
    method: 'get',
    params: query
  })
}

// 查询水晶账户记录分页
export function pageAccount(query) {
  return request({
    url: '/biz/userCrystalAccountBill/getCrystalAccountBillPage',
    method: 'get',
    params: query
  })
}

// 查询水晶账户记录详细
export function getAccount(data) {
  return request({
    url: '/biz/userCrystalAccountBill/detail',
    method: 'get',
    params: data
  })
}

// 新增水晶账户记录
export function addAccount(data) {
  return request({
    url: '/biz/userCrystalAccountBill/add',
    method: 'post',
    data: data
  })
}

// 修改水晶账户记录
export function updateAccount(data) {
  return request({
    url: '/biz/userCrystalAccountBill/edit',
    method: 'post',
    data: data
  })
}

// 删除水晶账户记录
export function delAccount(data) {
  return request({
    url: '/biz/userCrystalAccountBill/delete',
    method: 'post',
    data: data
  })
}

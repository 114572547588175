<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="钻石账单记录id " prop="diamondBillId" >
        <a-input v-model="form.diamondBillId" placeholder="请输入钻石账单记录id " />
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="订单号" prop="orderNo" >
        <a-input v-model="form.orderNo" placeholder="请输入订单号" />
      </a-form-model-item>
      <a-form-model-item label="操作前金币数量" prop="beforeGold" >
        <a-input v-model="form.beforeGold" placeholder="请输入操作前金币数量" />
      </a-form-model-item>
      <a-form-model-item label="变化水晶" prop="changeGold" >
        <a-input v-model="form.changeGold" placeholder="请输入变化水晶" />
      </a-form-model-item>
      <a-form-model-item label="操作后水晶" prop="afterGold" >
        <a-input v-model="form.afterGold" placeholder="请输入操作后水晶" />
      </a-form-model-item>
      <a-form-model-item label="支付水晶" prop="payGold" >
        <a-input v-model="form.payGold" placeholder="请输入支付水晶" />
      </a-form-model-item>
      <a-form-model-item label="标志符 + -" prop="sign" >
        <a-input v-model="form.sign" placeholder="请输入标志符 + -" />
      </a-form-model-item>
      <a-form-model-item label="1:收入 2：支出" prop="billType" >
        <a-select placeholder="请选择1:收入 2：支出" v-model="form.billType">
          <a-select-option v-for="(d, index) in billTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="审核状态" prop="auditStatus" >
        <a-radio-group v-model="form.auditStatus" button-style="solid">
          <a-radio-button v-for="(d, index) in auditStatusOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="审核驳回原因" prop="rejectReason" >
        <a-input v-model="form.rejectReason" placeholder="请输入审核驳回原因" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAccount, addAccount, updateAccount } from '@/api/biz/crystalAccountBill'

export default {
  name: 'CreateForm',
  props: {
    billTypeOptions: {
      type: Array,
      required: true
    },
    auditStatusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        diamondBillId: null,

        type: null,

        orderNo: null,

        beforeGold: null,

        changeGold: null,

        afterGold: null,

        payGold: null,

        sign: null,

        billType: null,

        auditStatus: 0,

        rejectReason: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        diamondBillId: null,
        type: null,
        orderNo: null,
        beforeGold: null,
        changeGold: null,
        afterGold: null,
        payGold: null,
        sign: null,
        billType: null,
        auditStatus: 0,
        rejectReason: null,
        createTime: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAccount({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAccount(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAccount(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
